import React from 'react'
import Layout from '../../../templates/layout'
import Seo from '../../../helpers/seo'
import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import Content from './content'
import Banner from '../../../components/molecules/Banner'
import content from './content.json'

const { relational } = content

const CantareiraSeca = () => {
  return (
    <Layout page="Cantareira Seca">
      <Banner image="cantareira-banner.webp" />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}
export const Head = () => <Seo title="Cantareira Seca" />

export default CantareiraSeca
